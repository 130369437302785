import { Image } from 'vant';
export default {
  components: {
    [Image.name]: Image
  },
  created() {
    document.title = "职业测试";
  },
  methods: {
    nextClick() {
      window.location.href = "https://quiz.gupaoedu.cn/paper/79";
    }
  }
};