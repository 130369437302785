import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b2a7aa42"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "images"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_image, {
    class: "back-image",
    fit: "cover",
    src: "http://gp-www-cdn.oss-cn-shenzhen.aliyuncs.com/gupao-edu-college/assets/zhiye_2.png"
  }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_image, {
    src: "http://gp-www-cdn.oss-cn-shenzhen.aliyuncs.com/gupao-edu-college/assets/zhiye_1.png"
  }), _createElementVNode("div", {
    class: "next-btn",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.nextClick && $options.nextClick(...args))
  })])]);
}